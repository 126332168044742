import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;

  button .button {
    cursor: pointer;
  }

  img {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  div {
    width: auto;
  }
`;
