/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useContext } from 'react';
import React, { useEffect, useState, useContext } from 'react';

import DashMenu from '~/Components/DashMenu';

import { Context } from '~/Context/AuthContext';

import api, { getAuthorizationHeader } from '~/services/api';

import fortreesImage from '~/assets/ForTrees512.png';

// import { parseISO } from 'date-fns';

// import { Context } from '~/Context/AuthContext';
// import { Context as CartContext } from '~/Context/CartContext';

import ProductCard from '~/Components/ProductCard';

import { Container } from './styles';

const images = [
  {
    productname: 'ForTrees Pro',
    image: fortreesImage,
    message:
      'Sistema ideal para empresas que fazem levantamentos períodicos para análise  da produtividade florestal',
  },
  {
    productname: 'ForTrees',
    image: fortreesImage,
    message:
      'Sistema ideal para engenheiros florestais que prestam serviços de inventário florestal',
  },
];

function Dashboard() {
  const [products, setproducts] = useState([]);
  const clientSys = JSON.parse(sessionStorage.getItem('clientSystems')) || [];
  const { checkSessionToken } = useContext(Context);

  // function onlyUnique(value, index, self) {
  //   return self.indexOf(value) === index;
  // }

  useEffect(() => {
    // setLoading(true);
    async function loadProducts() {
      const response = await api.get('/products', {
        headers: { Authorization: getAuthorizationHeader() },
      });

      // const data = response.data.map((product) => ({
      //   ...product,
      // }));

      const { data } = response;

      if (clientSys.length > 0) {
        // try {

        const notExpiredSys = clientSys.filter(
          (m) => !(m.expires_in > new Date().toUTCString())
        );

        console.log(clientSys.length > 0);

        const clientListSys = notExpiredSys.map((c) => c.product_id);

        const filtered = data.filter((d) => !clientListSys.includes(d.id));

        setproducts(filtered);
      } else {
        setproducts(data);
      }
      // } catch (err) {
      //   console.log(err);
      // }
    }
    checkSessionToken();
    loadProducts();
  }, [checkSessionToken]);

  // const { authenticated } = useContext(Context);
  // console.log(authenticated);

  // const { addToCart, cartList } = useContext(CartContext);

  // function onlyUnique(value, index, self) {
  //   return self.indexOf(value) === index;
  // }

  // const cartListItem = cartList.map((c) => c.item);

  // const UniqueList = cartListItem.filter(onlyUnique);

  // const itemsToShow = compras.filter((c) => c.item !== UniqueList);

  function getImage(productname) {
    const imageSelected = images.filter((l) => l.productname === productname);

    return imageSelected[0].image;
  }

  function getMessage(productname) {
    const imageSelected = images.filter((l) => l.productname === productname);

    return imageSelected[0].message;
  }

  return (
    <>
      <DashMenu />
      <Container>
        {/* <div>
          <h1>Controle|Dashboard</h1>
        </div> */}
        <div>
          <h1>Ofertas Disponíveis</h1>
        </div>

        <div>
          {products
            ? products.map((item) => (
                <ProductCard
                  key={item.id}
                  item={item.id}
                  name={item.productname}
                  image={getImage(item.productname)}
                  price={item.product_price}
                  symbol="🌳"
                  text={getMessage(item.productname)}
                  linkTo="/cart"
                  nMobile={item.collectors_limit}
                  nTrees={item.items_limit_permonth}
                />
              ))
            : ''}
        </div>
      </Container>
    </>
  );
}

export default Dashboard;
