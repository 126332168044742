import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  /* padding-top: 20px; */
  width: auto;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-top: 200px;
  text-align: center;
  flex-direction: column;

  div {
    margin: 10px;
  }

  @media only screen and (max-width: 768px) {
    width: auto;
    height: auto;
    display: flex;
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    overflow: scroll;

    div {
      width: auto;
      height: auto;
      flex-direction: column;
    }
  }
`;
