import React, { useEffect, useContext } from 'react';
// import PropTypes, { elementType } from 'prop-types';
import PropTypes from 'prop-types';

// import { Link } from 'react-router-dom';

import { Context as CartContext } from '~/Context/CartContext';

import { BsFillCartPlusFill } from 'react-icons/bs';

import { motion, useAnimation } from 'framer-motion';

import { useInView } from 'react-intersection-observer';

// import { Container, linkStyle } from './styles';
import { toast } from 'react-toastify';
import { Container } from './styles';

export default function ProductCard({
  name,
  item,
  price,
  image,
  text,
  nMobile,
  nTrees,
}) {
  const control = useAnimation();
  const [ref, inView] = useInView();

  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const product = {
    item,
    product: name,
    price,
  };

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  const { addToCart } = useContext(CartContext);

  function handleAddToCart(e) {
    const res = addToCart(e);

    if (res === 'success') {
      toast.success('Produto adicionado ao carrinho');
    } else {
      toast.info('Produto já está no carrinho');
    }
  }

  return (
    <Container>
      <div className="card">
        <div className="image">
          <img src={image} alt="" />
        </div>
        <div>
          <h1>{name}</h1>
        </div>
        <div className="text">
          <h2>{text}</h2>
        </div>
        {/* <div>{children}</div> */}
        <div className="table">
          <div>
            <h3>🖥️ Web</h3>
            <h3>📱 Mobile</h3>
            <h3>💽 Dados</h3>
            <h3>🌳 Amostras</h3>
          </div>
          <div>
            <h3>✅</h3>
            <h3>✅</h3>
            <h3>✅</h3>
            <h3>✅</h3>
          </div>
          <div>
            <h3>1 Usuário</h3>
            <h3>{nMobile} Usuários</h3>
            <h3>+1 ano</h3>
            <h3>{nTrees} / mês</h3>
          </div>
        </div>
        <motion.div
          className="box"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
          onClick={() => handleAddToCart(product)}
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="price">
              <h2>Preço: {(price / 12).toFixed(2)} R$ / mês</h2>
              <h3>
                Adicionar ao carrinho <BsFillCartPlusFill />
              </h3>
            </div>
          </motion.button>
        </motion.div>
      </div>
    </Container>
  );
}

ProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  item: PropTypes.number.isRequired,
  nMobile: PropTypes.number.isRequired,
  nTrees: PropTypes.number.isRequired,
  // children: PropTypes.element,
};

// ProductCard.defaultProps = {
//   children: elementType,
// };
