import styled, { css } from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background: rgb(0, 105, 255);
  color: white;
  align-items: center;
  font: 14px 'Roboto', sans-serif;

  div {
    text-decoration: none;
    display: flex;
    width: auto;
    height: auto;
    flex-direction: row;
    align-items: center;
    border: 0;
    padding: 0;
    margin-right: 0;

    a.title {
      margin-left: 0;
      margin-right: auto;
      color: rgb(230, 230, 230);
      text-decoration: none;
      display: flex;
      flex-direction: row;
    }
    a.title:hover {
      color: white;
    }
    a.policytitle {
      margin-left: 0;
      margin-right: auto;
      color: rgba(230, 230, 230, 0.5);
      text-decoration: none;
      display: flex;
      flex-direction: row;
      h6 {
        font: 12px 'Roboto', sans-serif;
      }
    }
    a.policytitle:hover {
      color: white;
    }
  }

  div .right {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 0;
    float: right;
    align-items: center;

    a {
      color: rgb(235, 235, 235);
      font: 24px 'Roboto', sans-serif;
      padding: 10px;
      text-decoration: none;
      margin: 10px;
    }
    a:hover {
      border-radius: 5px;
      background: white;
      color: rgb(0, 105, 255);
    }

    a.signup {
      background: rgb(235, 235, 235);
      padding: 10px;
      color: rgb(0, 105, 255);
      font: 24px 'Roboto', sans-serif;
      border-radius: 5px;
    }
    a.signup:hover {
      background: white;
    }
  }
  div .middle {
    width: auto;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 0;
    float: right;
    align-items: right;

    a {
      color: rgba(255, 255, 255, 0.5);
      padding: 10px;
      text-decoration: none;
      margin: 10px;
    }
    a:hover {
      color: rgb(235, 235, 235);
    }
  }

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    width: auto;
    height: 100%;
    align-items: center;
    max-width: 40%;
    background: rgba(0, 105, 255, 0.9);

    ${(props) =>
      props.type === 'close' &&
      css`
        transition: 0.5s;
        transform: translateX(-105%);
      `}

    ${(props) =>
      props.type === 'open' &&
      css`
        transition: 0.5s;
        transform: translateX(0%);
      `}

        div,
        div .middle,
        div .right {
      overflow: hidden;
      margin-left: 0;
      align-items: center;
      display: flex;
      width: 100%;
      height: auto;
      flex-direction: column;
      text-align: center;

      h1,
      a,
      a.hover,
      a.signup,
      a.title {
        text-decoration: none;
        align-items: center;
        flex-direction: column;
        margin-left: 0;
        font-size: 18px;

        div {
          text-align: center;
          svg {
            size: 100px;
          }
        }
      }
      a.policytitle {
        font-size: 14px;
      }
    }
  }
`;

export const ButtonIcon = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 5px;
    z-index: 201;

    height: auto;

    ${(props) =>
      props.type === 'open' &&
      css`
        transition: 0.5s;
        transform: translate(40vw) rotateZ(180deg);
      `}

    ${(props) =>
      props.type === 'close' &&
      css`
        transition: 0.5s;
        transform: translate(0%) rotateZ(0deg);
      `}
    button, svg {
      width: 50px;
      font-size: 42px;
      font-weight: 700;
      color: #000;
      background: none;
      border: none;
    }
  }
`;
