import React from 'react';

import { RiDatabase2Fill, RiPhoneFill } from 'react-icons/ri';
import { Container } from './styles';

function Footer() {
  return (
    <Container>
      <div className="logo">
        <RiDatabase2Fill size={50} />
        <h1>Datagro Soluções Tecnológicas</h1>
      </div>
      <div>
        <h3> datagro.br@gmail.com</h3>
      </div>
      <div>
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=5566992276047"
          rel="noreferrer"
        >
          <RiPhoneFill />
          <h4>Fale conosco</h4>
        </a>
      </div>
    </Container>
  );
}

export default Footer;
