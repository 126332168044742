import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;

  div.title {
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 50px;
    height: 50px;
  }

  h2 {
    padding-top: 30px;
    text-align: center;
  }

  div.table {
    padding: 10px;
    width: 100%;
    height: auto;
    display: flex;
    box-shadow: grey 2px 2px 5px 2px;
    background: rgba(255, 255, 255, 0.5);
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    align-content: center;
    align-items: center;
    overflow: scroll;
    flex-direction: column;

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 105, 255, 1);
    }

    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    div.head,
    div.body {
      border: 1px;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  div.tr,
  div.td,
  div.th {
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: row;

    button {
      width: auto;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-size: 22px;
      padding: 5px;
      border-radius: 5px;
      background: rgba(0, 105, 255, 0.7);
      color: rgba(255, 255, 255, 1);
    }
    button:hover {
      background: rgba(0, 105, 255, 1);
    }
  }
  div.th,
  div.td {
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
    width: 200px;
    height: 100px;
  }
  div.th {
    font-weight: bold;
  }
  div.tr {
    display: flex;
  }

  div.confimado {
    color: green;
    font-weight: 500;
  }

  div.aguardando {
    color: red;
  }
`;
