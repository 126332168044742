import React, { useState, useRef, useContext } from 'react';

import DashMenu from '~/Components/DashMenu';

import { Context } from '~/Context/AuthContext';

import { Form } from '@unform/web';
import Input from '~/Components/Input';

import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api, { getAuthorizationHeader } from '~/services/api';

import { Container } from './styles';

import AvatarInput from './Avatarinput';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome do projeto é obrigatório'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  cpf: Yup.number().required(),
  street: Yup.string().required('O nome da rua é obrigatória'),
  city: Yup.string().required('O nome do município é obrigatório'),
  state: Yup.string().required('O nome da Unidade Federativa é obrigatória'),
  house_number: Yup.number().required(),
  // oldPassword: Yup.string().required('A senha é obrigatória').min(6),
  // password: Yup.string().required('A senha é obrigatória').min(6),
  // confirmPassword: Yup.string().required('A senha é obrigatória').min(6),
  oldPassword: Yup.string().min(6),
  password: Yup.string()
    .min(6)
    .when('oldPassword', (oldPassword, field) =>
      oldPassword ? field.required() : field
    ),
  confirmPassword: Yup.string().when('password', (password, field) =>
    password ? field.required().oneOf([Yup.ref('password')]) : field
  ),
});

function Profile() {
  const formRef = useRef(null);
  const [className, setClassname] = useState('');
  const client = JSON.parse(sessionStorage.getItem('clientInfo')); // Não armazenar no navegador; usar um useEffect e api para pegar os dados do cliente

  const { checkSessionToken } = useContext(Context);

  function functionThatResetsForm() {
    // You can also clear a single input value
    formRef.current.clearField('oldPassword');
    formRef.current.clearField('password');
    formRef.current.clearField('confirmPassword');
  }

  async function handleSubmit(data) {
    checkSessionToken();

    const {
      name,
      email,
      cpf,
      street,
      state,
      city,
      oldPassword,
      password,
      confirmPassword,
      house_number,
      cep,
      cel,
    } = data;

    const newData = {
      name,
      email,
      cpf,
      street,
      state,
      city,
      oldPassword,
      password,
      confirmPassword,
      house_number: JSON.parse(house_number),
      cep: JSON.parse(cep),
      cel: cel || null,
    };

    const isValid = await schema.isValid(newData);

    if (isValid) {
      try {
        const response = await api.put('clients', newData, {
          headers: { Authorization: getAuthorizationHeader() },
        });

        sessionStorage.setItem('clientInfo', JSON.stringify(response.data));
        toast.success('Dados atualizados com sucesso');
        setClassname('');
        functionThatResetsForm();
      } catch (err) {
        toast.error(`Verifique seus dados`);
      }
    } else {
      setClassname('alert');
      toast.error('Preencha os campos obrigatórios');
    }
  }

  return (
    <>
      <DashMenu />
      <Container>
        {/* {client.avatar ? (
          <div>
            <img src={client.avatar.url} alt="avatar" />
          </div>
        ) : (
          <div>
            <BsPersonCircle size={100} />
            <p>Arthur Chaves</p>
          </div>
        )} */}

        <Form
          schema={schema}
          initialData={client}
          onSubmit={(data) => handleSubmit(data)}
          ref={formRef}
        >
          <div>
            <AvatarInput name="avatar_id" />
          </div>
          <div>
            <Input
              className={className}
              name="name"
              type="name"
              placeholder="Nome completo"
            />
          </div>
          <div>
            <Input
              className={className}
              name="email"
              type="email"
              placeholder="Email"
            />
          </div>
          <div>
            <Input
              className={className}
              name="cpf"
              type="number"
              placeholder="CPF"
            />
          </div>
          <div>
            <Input name="cel" type="number" placeholder="Telefone" />
          </div>
          <div>
            <Input
              className={className}
              name="street"
              type="name"
              placeholder="Endereço"
            />
          </div>
          <div>
            <Input
              className={className}
              name="city"
              type="name"
              placeholder="Cidade"
            />
          </div>
          <div>
            <Input
              className={className}
              name="state"
              type="name"
              placeholder="Estado"
            />
          </div>
          <div>
            <Input
              className={className}
              name="house_number"
              type="number"
              placeholder="Número da casa"
            />
          </div>
          <div>
            <Input
              className={className}
              name="cep"
              type="number"
              placeholder="CEP"
            />
          </div>
          <div>
            <Input
              className={className}
              name="oldPassword"
              placeholder="Senha Atual"
              type="password"
            />
          </div>
          <div>
            <Input
              className={className}
              name="password"
              placeholder="Nova senha"
              type="password"
            />
          </div>
          <div>
            <Input
              className={className}
              name="confirmPassword"
              placeholder="Repita a nova senha"
              type="password"
            />
          </div>
          <button type="submit">Alterar dados</button>
        </Form>
      </Container>
    </>
  );
}

export default Profile;
