/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';

// import { Link } from 'react-router-dom';
import { Context } from '~/Context/CartContext';

import DashMenu from '~/Components/DashMenu';
import ProductCart from '~/Components/ProductCart';

import { BsCartCheckFill } from 'react-icons/bs';
import { Container } from './styles';

function Cart() {
  const { cartList } = useContext(Context);

  // const handleRemoveItem = (e) => {
  //   setnewCartList(newcartList.filter((c) => c.item !== e.item));
  // };

  const lista = cartList.map((i) => i.price);
  const soma = lista.reduce((total, item) => total + item, 0);

  return (
    <>
      <DashMenu />
      <Container>
        <h1>
          Meu Carrinho <BsCartCheckFill />
        </h1>
        {soma > 0 ? (
          // <ProductCart>
          <div>
            {cartList.map((p) => (
              <ProductCart
                key={p.item}
                item={p.item}
                product={p.product}
                plan={p.plan}
                price={p.price}
              />
            ))}
          </div>
        ) : (
          <>
            <br />
            <h2>Seu carrinho está vazio.</h2>
          </>
        )}
      </Container>
    </>
  );
}

export default Cart;
