import axios from 'axios';

export const getToken = () =>
  localStorage.getItem('token') ? localStorage.getItem('token') : null;

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

const api = axios.create({
  baseURL: 'https://www.back.datagro.tec.br', // DO
  // baseURL: 'http://10.3.251.38:3333', // IF
  headers: { Authorization: getAuthorizationHeader },
});

// axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
//   'token'
// )}`;
// // axios.defaults.headers.common.Authorization = `Bearer ${null}`;

// // let token = localStorage.getItem('token');
// // let token = null;

// const api = axios.create({
//   baseURL: 'http://192.168.43.168:3333', // cel
//   // baseURL: 'http://192.168.6.4:3333', // Racnho5G
//   // baseURL: 'http://10.3.251.38:3333', // IF
//   // headers: {
//   //   Authorization: `Bearer ${token}`,
//   // },
// });

export default api;
