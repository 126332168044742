import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    form {
      align-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;

      div, input, button {
        max-width: 100%;

      }

      input {
        text-align: center;
      }
    }
  }

  img {
    width: 150px;
    height: auto;
    border-radius: 50%;
  }

  form {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 10px;

    div {
      width: 100%;
      width: auto;
      display: flex;
      flex-direction: row;

      input {
        display: flex;
        /* width: 225px; */
      }
      span {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          padding: 15px;
          display: flex
          flex-direction: column;
          text-align: left;
          color: red;
        }

        button {
          width: 100%;
        }
    }

    input {
      width: 500px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      border-radius: 5px;
      margin: 5px;
      padding: 10px;
      background-color: rgba(0, 0, 150, 0.1);
      border: 1px solid rgba(0, 0, 150, 0.1);
      color: black;
      text-align: left;
      font: 16px 'Roboto', sans-serif;
    }

    input.alert {
      border: 1px solid red;
    }

    a {
      text-align: end;
      font: 14px 'Roboto', sans-serif;
      text-decoration: none;
      color: black;
    }

    button {
      margin-top: 5px;
      width: 100%;
      max-width: 100%;
      height: 44px;
      background: rgba(0, 105, 255, 0.7);
      font-weight: bold;
      color: #fff;
      border-radius: 4px;
      font-size: 14px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, 'rgba(0, 105, 255, 1)')};
      }
      font: 24px 'Roboto', sans-serif;
    }
`;
