import React from 'react';
// import React, { useState } from 'react';

import MainMenu from '~/Components/MainMenu';
import Footer from '~/Components/Footer';
// import ProductCard from '~/Components/ProductCard';

import { motion, useScroll, useSpring } from 'framer-motion';

import mobileApp from '~/assets/1.png';
import FrontApp from '~/assets/2.png';
import ForTrees from '~/assets/3b.png';
import { Container } from './styles';

export default function Main() {
  const { scrollYProgress } = useScroll();

  // const [plan, setPlan] = useState('Básico');

  // function handleForward() {
  //   setPlan('Profissional');
  // }

  // function handleBack() {
  //   setPlan('Básico');
  // }

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <>
      <MainMenu />
      <Container>
        <div>
          <motion.div style={{ scaleX }} className="progress-bar" />
        </div>
        <div>
          <h1 className="main">Sistemas de Apoio à Gestão do Agronegócio</h1>
        </div>
        <div>
          <h2 className="main">
            Colete, armazene e monitore as informações do seu agronegócio em
            tempo real
          </h2>
        </div>
        <div>
          <img src={ForTrees} alt="Google Example" />
        </div>
        <div>
          <h2 className="main">Veja como funcionam as ferramentas</h2>
        </div>
        <div>
          <div>
            <img src={mobileApp} alt="Google Example2" />
          </div>
          <div className="halfsize">
            <h1 className="main">
              Cadastre seus prestadores e conceda acesso aos coletores de dados
            </h1>
            <h3 className="main">
              Os coletores de dados são desenvolvidos com a premissa de que
              devem funcionar Offline Primeiro. Assim irão funcionar no campo
              mesmo que não haja conectividade com a rede móvel
            </h3>
          </div>
          <div />
        </div>
        <div>
          <div className="halfsize">
            <h1 className="main">
              Crie e monitore as coletas de dados para seus prestadores de
              serviço
            </h1>
            <h3 className="main">
              O gestor poderá cadastrar seus prestadores e enviar as ordens de
              serviço. E, assim que os dados forem recebidos dos aplicativos,
              poderá avaliar as informações obtidas de maneira a facilitar suas
              decisões.
            </h3>
          </div>
          <div />
          <div>
            <img src={FrontApp} alt="Example3" className="desktop" />
          </div>
        </div>
        <div>
          <div>
            <img
              src="https://d3s61v63vo5uv6.cloudfront.net/wp-content/uploads/2018/01/plataforma-web.png"
              alt="Example3"
            />
          </div>
          <div className="halfsize">
            <h1 className="main">
              Entregue ao seu cliente relatórios digitais
            </h1>
            <h3 className="main">
              Na Plataforma Web, em analytics, analise os dados coletados e
              produza relatórios digitais de alta qualidade para enviar aos seus
              clientes.
            </h3>
          </div>
          <div />
        </div>

        <div>
          <h2 className="main">Conheça nossos sistemas:</h2>
        </div>
        <div>
          <motion.div style={{ scaleX }} className="progress-bar" />
        </div>
        <div>
          <h1 className="main">ForTrees</h1>
        </div>
        <div>
          <h2 className="main">
            Sistema ideal para Engenheiros Florestais que prestam serviços de
            inventário florestal.
          </h2>
        </div>
        <div>
          <img src={ForTrees} alt="ForTrees Example" />
        </div>
        <div>
          <h1 className="main">Outros sistemas</h1>
        </div>
        <div>
          <h2 className="main">
            Nossa próxima solução pode ser desenvolvida sob demanda para você.
          </h2>
        </div>
      </Container>
      <Footer />
    </>
  );
}
