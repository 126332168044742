import React, { useState, useEffect, useCallback } from 'react';

import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { MdDeleteForever } from 'react-icons/md';

import DashMenu from '~/Components/DashMenu';
import api, { getAuthorizationHeader } from '~/services/api';
import { toast } from 'react-toastify';
import fortreesImage from '~/assets/ForTrees512.png';
import { Container } from './styles';

function ClientSystems() {
  const [sells, setSells] = useState([]);

  const loadSellsCall = useCallback(() => {
    async function loadSells() {
      const response = await api.get('/sells', {
        headers: { Authorization: getAuthorizationHeader() },
      });

      const data = response.data.map((sell) => ({
        ...sell,
      }));

      setSells(data);
      sessionStorage.setItem('clientSystems', JSON.stringify(data));
    }
    loadSells();
  }, []);

  useEffect(() => {
    // setLoading(true);
    async function loadSells() {
      const response = await api.get('/sells', {
        headers: { Authorization: getAuthorizationHeader() },
      });

      const data = response.data.map((sell) => ({
        ...sell,
      }));

      setSells(data);
      sessionStorage.setItem('clientSystems', JSON.stringify(data));
    }
    loadSells();
  }, []);

  const images = [
    {
      productname: 'ForTrees Pro',
      image: fortreesImage,
    },
    {
      productname: 'ForTrees',
      image: fortreesImage,
    },
  ];

  const linksList = [
    {
      productname: 'ForTrees',
      acessLink: 'https://www.fortrees.datagro.tec.br',
      img: fortreesImage,
    },
    {
      productname: 'ForTrees Pro',
      acessLink: 'https://www.fortrees.datagro.tec.br',
      img: fortreesImage,
    },
  ];

  function getLinkAccess(productname) {
    const item = linksList.filter((l) => l.productname === productname);

    window.open(item[0].acessLink);
  }

  async function handleDelete(e) {
    try {
      await api.delete(`/sells/${e}`, {
        headers: { Authorization: getAuthorizationHeader() },
      });

      loadSellsCall();

      toast.success('Pedido Cancelado');
    } catch (err) {
      toast.error(`${err}`);
    }
  }

  function getImage(productname) {
    const imageSelected = images.filter((l) => l.productname === productname);

    return imageSelected[0].image;
  }

  return (
    <>
      <DashMenu />
      <Container>
        <div className="title">
          <h1>Meus sistemas</h1>
        </div>

        <div>
          {sells.length === 0 ? (
            <h2>Nenhum Software foi adquirido ainda.</h2>
          ) : (
            <div className="table">
              <div className="head">
                <div className="tr">
                  <div className="th">Produto</div>
                  <div className="th">Início da assinatura</div>
                  <div className="th">Fim da assinatura</div>
                  <div className="th">Situação do pedido</div>
                  <div className="th">Link de acesso</div>
                  <div className="th">Cancelar</div>
                </div>
              </div>
              <div className="body">
                {sells.map((e) => (
                  <div key={e.id} className="tr">
                    <div className="td">
                      <div>{e.product.productname}</div>

                      <img src={getImage(e.product.productname)} alt="logo" />
                    </div>
                    <div className="td">
                      {format(parseISO(e.updatedAt), 'dd/MM/yyyy', {
                        locale: pt,
                      })}
                    </div>
                    <div className="td">
                      {format(parseISO(e.expires_in), 'dd/MM/yyyy', {
                        locale: pt,
                      })}
                    </div>
                    {e.isconfirmed ? (
                      <div className="td confirmado">Pagamento Confirmado</div>
                    ) : (
                      <div className="td aguardando">
                        Aguardando confirmação de pagamento.
                      </div>
                    )}

                    <div className="td">
                      <button
                        type="submit"
                        onClick={() => getLinkAccess(e.product.productname)}
                      >
                        Acessar
                      </button>
                    </div>
                    {e.isconfirmed ? (
                      <div className="td"> </div>
                    ) : (
                      <div className="td">
                        <button
                          type="submit"
                          onClick={() => handleDelete(e.id)}
                        >
                          <MdDeleteForever />
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            // <table>
            //   <thead>
            //     <tr>
            //       <th>Produto</th>
            //       <th>Início da assinatura</th>
            //       <th>Fim da assinatura</th>
            //       <th>Situação do pedido</th>
            //       <th>Link de acesso</th>
            //       <th>Cancelar</th>
            //     </tr>
            //   </thead>
            //   <tbody>
            //     {sells.map((e) => (
            //       <tr key={e.id}>
            //         <td>
            //           <div>{e.product.productname}</div>

            //           <img src={getImage(e.product.productname)} alt="logo" />
            //         </td>
            //         <td>
            //           {format(parseISO(e.updatedAt), 'dd/MM/yyyy', {
            //             locale: pt,
            //           })}
            //         </td>
            //         <td>
            //           {format(parseISO(e.expires_in), 'dd/MM/yyyy', {
            //             locale: pt,
            //           })}
            //         </td>
            //         {e.isconfirmed ? (
            //           <td>Pagamento Confirmado</td>
            //         ) : (
            //           <td className="aguardando">
            //             Aguardando confirmação de pagamento.
            //           </td>
            //         )}

            //         <td>
            //           <button
            //             type="submit"
            //             onClick={() => getLinkAccess(e.product.productname)}
            //           >
            //             Acessar
            //           </button>
            //         </td>
            //         {e.isconfirmed ? (
            //           <td> </td>
            //         ) : (
            //           <td>
            //             <button
            //               type="submit"
            //               onClick={() => handleDelete(e.id)}
            //             >
            //               <MdDeleteForever />
            //             </button>
            //           </td>
            //         )}
            //       </tr>
            //     ))}
            //   </tbody>
            // </table>
          )}
        </div>
      </Container>
    </>
  );
}

export default ClientSystems;
