/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-promise-executor-return */
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BsFillPersonCheckFill } from 'react-icons/bs';
// import ReCAPTCHA from "react-google-recaptcha";
import api from '~/services/api';
import { Container } from './styles';

function UserValidation() {
  const { token } = useParams();
  const [message, setMessage] = useState(false);

  const navigate = useNavigate();
  // const recaptchaRef = useRef();

  async function Validar() {
    // const recaptchaValue = recaptchaRef.current.getValue();
    // if (recaptchaValue) {
    try {
      await api.put(`/clientvalidation/${token}`);
      setMessage(true);
      toast.success('Conta Validada com sucesso!');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      navigate('/signin');
    } catch (err) {
      toast.error('Não foi possível validar esta conta!');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      navigate('/signin');
    }
    // } else {
    //   toast.error("Prove que você não é um robô.");
    // }
  }
  return (
    <>
      <Container>
        {message ? (
          <div>
            <h3>
              <BsFillPersonCheckFill /> Conta validada com sucesso!!!
            </h3>
          </div>
        ) : (
          <div>
            <h2>Datagro Soluções Tecnológicas</h2>
            <button onClick={Validar} type="submit">
              Validar Conta
            </button>
          </div>
        )}
      </Container>
    </>
  );
}

export default UserValidation;
