import React from 'react';
import { useParams } from 'react-router-dom';

// import { Form, Input } from '@rocketseat/unform';

// import * as Yup from 'yup';

import Client from '~/Components/UserReset';

import { Container } from './styles';

// const schema = Yup.object().shape({
//   password: Yup.string().required('A senha é obrigatória'),
//   confirmpassword: Yup.string().required('A senha é obrigatória'),
// });

function ClientForgotPass() {
  const { token } = useParams();

  return (
    <Container>
      <Client token={token} subdomain="clientrecover" />
    </Container>
  );
}

export default ClientForgotPass;
