import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;

  span {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    display: flex
    flex-direction: column;
    text-align: left;
    color: red;
  }

  a {
    width: 100%;
    color: rgb(0, 105, 255);
  }

  div.card {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 330px;
    height: auto;
    box-shadow: grey 10px 5px 10px 5px;
    background: #fff;
    border-radius: 5px;
    vertical-align: middle;
    color: rgb(0, 105, 255);
    padding: 10px;


    div.text {
      height: 155px;
      text-align: justify;
      h2 {
        height: 100%;
        color: rgba(33, 33, 33, 0.8);
      }
    }

    h1 {
      padding-top: 10px;
      width: 100%;
      text-align: center;
    }

    img {
      width: 150px;
      height: 150px;
    }
  }

  div.price {
    width: 100%;
    border-radius: 5px;
    background: rgb(0, 105, 255);
    padding: 10px;

    h2,
    h3 {
      color: white;
      padding: 5px;
    }
  }

  div.table {
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
`;

export const linkStyle = {
  textDecoration: 'none',
  color: 'red',
};
