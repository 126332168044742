import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';
import Main from '~/pages/Main';
import DataPolicys from '~/pages/DataPolicys';
import ContactUs from '~/pages/ContactUs';
import About from '~/pages/About';
import Dashboard from '~/pages/Dashboard';
import ClientSystems from '~/pages/ClientSystems';
import ClientForgotPass from '~/pages/ClientForgotPass';
import UserValidation from '~/pages/UserValidation';
import Cart from '~/pages/Cart';
import Profile from '~/pages/Profile';
import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';
import ForgotPass from '~/pages/ForgotPass';
import RouteWrapper from './Route';

export default function Routes() {
  return (
    <Switch>
      <Route element={<RouteWrapper />}>
        <Route path="/" element={<Main />} />
        <Route path="/datapolicys" element={<DataPolicys />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpass" element={<ForgotPass />} />
        <Route path="/clientforgotpass/:token" element={<ClientForgotPass />} />
        <Route path="/uservalidation/:token" element={<UserValidation />} />
        <Route path="/about" element={<About />} />
      </Route>
      <Route element={<RouteWrapper isPrivate />}>
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/clientsystems" element={<ClientSystems />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/cart" element={<Cart />} />
      </Route>
    </Switch>
  );
}
