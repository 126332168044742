/* eslint-disable react/jsx-no-bind */
import React, { useState, useRef } from 'react';
// import React, { useContext, useState } from 'react';

import { RiDatabase2Fill } from 'react-icons/ri';

import MainMenu from '~/Components/MainMenu';

// import { Context } from '~/Context/AuthContext';

import { Link, useNavigate } from 'react-router-dom';

import { Form } from '@unform/web';
import Input from '~/Components/Input';

import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { Container } from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  cpf: Yup.number().required().max(99999999999),
  street: Yup.string().required(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  house_number: Yup.number().required(),
  password: Yup.string()
    .min(6)
    .when('oldPassword', (oldPassword, field) =>
      oldPassword ? field.required() : field
    ),
});

export default function SignUp() {
  // const { authenticated } = useContext(Context);
  const formRef = useRef(null);
  const [className, setClassname] = useState('');

  const navigate = useNavigate();

  // console.log(authenticated);

  function functionThatResetsForm() {
    // You can also clear a single input value
    formRef.current.reset();
  }

  async function handleSubmit(data) {
    const {
      name,
      email,
      cpf,
      street,
      state,
      city,
      password,
      house_number,
      cep,
    } = data;

    const newData = {
      name,
      email,
      cpf,
      street,
      state,
      city,
      password,
      house_number: house_number ? JSON.parse(house_number) : null,
      cep: cep ? JSON.parse(cep) : null,
    };

    const isValid = await schema.isValid(newData);

    if (isValid) {
      try {
        const response = await api.post('clients', data);

        if (response.status === 200) {
          toast.success('Cadastro realizado com sucesso');
          setClassname('');
          functionThatResetsForm();
          // eslint-disable-next-line no-promise-executor-return
          await new Promise((resolve) => setTimeout(resolve, 5000));
          navigate('/signin');
        }
      } catch (err) {
        toast.error(`${JSON.stringify(err.response.data.error)}`);
      }
    } else {
      setClassname('alert');
      toast.error('Preencha os campos obrigatórios corretamente.');
    }
  }

  return (
    <>
      <MainMenu />
      <Container>
        <div>
          <h1>
            <RiDatabase2Fill size={50} color="rgb(0, 0, 0)" />
            Datagro Soluções Tecnológicas
          </h1>
        </div>
        <Form
          schema={schema}
          onSubmit={(data) => handleSubmit(data)}
          ref={formRef}
        >
          <Input
            name="name"
            type="name"
            placeholder="Nome completo"
            className={className}
          />
          <Input
            className={className}
            name="email"
            type="email"
            placeholder="Email"
          />
          {/* <Input name="cel" type="number" placeholder="Telefone" /> */}
          <Input
            className={className}
            name="cpf"
            type="name"
            placeholder="CPF (00011122233)"
          />
          <Input
            className={className}
            name="city"
            type="name"
            placeholder="Cidade"
          />
          <Input
            className={className}
            name="state"
            type="name"
            placeholder="Estado"
          />
          <Input
            className={className}
            name="street"
            type="name"
            placeholder="Rua, Bairro"
          />
          <Input
            className={className}
            name="house_number"
            type="number"
            placeholder="Número"
          />
          <Input
            className={className}
            name="cep"
            type="number"
            placeholder="CEP"
          />
          <Input
            className={className}
            name="password"
            placeholder="Crie uma senha com pelo menos seis dígitos"
            type="password"
          />
          <Link to="/signin">Entrar</Link>
          <button type="submit">Cadastrar</button>
        </Form>
      </Container>
    </>
  );
}
