/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const Context = createContext();

function CartProvider({ children }) {
  // const teste = sessionStorage.getItem('cartList');
  // console.log(teste);

  const [cartList, setCartList] = useState([]);
  const [cartListCount, setCartListCount] = useState(0);

  function addToCart(e) {
    const findOne = cartList.filter((c) => c.item === e.item);

    if (findOne.length === 0) {
      cartList.push(e);
      setCartListCount(cartList.length);
      setCartList(cartList);

      sessionStorage.setItem('cartList', cartList);
      sessionStorage.setItem('cartListCount', cartListCount);
      return 'success';
    }
    return 'alert';
  }

  function removeFromCart(e) {
    setCartList(cartList.filter((c) => c.item !== e.item));
    setCartListCount(cartList.length - 1);
    sessionStorage.setItem('cartList', cartList.item);
    sessionStorage.setItem('cartListCount', cartListCount);
  }

  async function buyProduct(e) {
    console.log(e.item);
    removeFromCart(e);
  }

  return (
    <Context.Provider
      value={{ cartList, cartListCount, addToCart, removeFromCart, buyProduct }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, CartProvider };

CartProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
