import React, { useState, useContext } from 'react';

import { RiDatabase2Fill } from 'react-icons/ri';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
// import { RiMenuUnfoldLine } from 'react-icons/ri';

import { Context as SidebarContext } from '~/Context/SidebarContext';

import { NavLink } from 'react-router-dom';
import { Container, ButtonIcon } from './styles';

const activeStyle = {
  textDecoration: 'underline',
  color: 'black',
};

function MainMenu() {
  const { sideBar, toogleSidebar } = useContext(SidebarContext);
  const [bar, setBar] = useState(<AiOutlineMenuUnfold />);

  function changeToogleSign() {
    if (sideBar === true) {
      setBar(<AiOutlineMenuUnfold />);
    } else {
      setBar(<AiOutlineMenuUnfold />);
    }
    toogleSidebar();
    return bar;
  }
  return (
    <aside>
      <Container type={sideBar === true ? 'open' : 'close'}>
        <div>
          <div>
            <NavLink to="/" className="title">
              <div>
                <RiDatabase2Fill size={50} />
              </div>
              <div>
                <h1>Datagro Soluções Tecnológicas</h1>
              </div>
            </NavLink>
            <div className="middle">
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/about"
                className="policytitle"
              >
                <h4>Sobre</h4>
              </NavLink>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/datapolicys"
                className="policytitle"
              >
                <h4>Política de Dados</h4>
              </NavLink>
            </div>
          </div>
          <div className="right">
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/signin"
            >
              Entrar
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              className="signup"
              to="/signup"
            >
              Criar a sua conta
            </NavLink>
          </div>
        </div>
      </Container>
      <ButtonIcon
        type={sideBar ? 'open' : 'close'}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={changeToogleSign}
      >
        <button type="submit">{bar}</button>
      </ButtonIcon>
    </aside>
  );
}

export default MainMenu;
