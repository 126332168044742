import styled, { css } from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  --lineheight: 75px;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background: rgb(0, 55, 155);
  align-items: center;
  font: 14px 'Roboto', sans-serif;
  color: white;

  h3 {
    padding: 5px;
  }

  a {
    color: rgb(230, 230, 230);
    height: var(--lineheight);
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  a:hover {
    background: white;
    color: rgb(0, 55, 155);
    border-radius: 5px;
  }

  div {
    display: flex;
    width: auto;
    height: auto;
    flex-direction: row;
    align-items: center;
    border: 0;
    padding: 0;
    margin: 0;

    button {
      width: auto;
      height: var(--lineheight);
      background: none;
      border: none;
      color: rgb(230, 230, 230);
      font: 24px 'Roboto', sans-serif;
      padding: 0 10px;
    }
    button:hover {
      border-radius: 5px;
      background: white;
      color: rgb(0, 55, 155);
    }

    img {
      padding: 5px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }

  div.right {
    display: flex;
    flex-direction: row;
    width: 50vw;
    max-width: 50%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    float: right;

    div {
      height: 100%;
      align-items: center;
      border: 0;
      margin: 0;
    }

    a {
      width: auto;
      height: var(--lineheight);
      margin-left: auto;
      margin-right: auto;
      color: white;
      font: 24px 'Roboto', sans-serif;
      align-items: center;
      text-align: center;
      padding: 5px 20px;
    }
    a:hover {
      color: rgb(0, 55, 155);
    }

    a.signup {
      height: var(--lineheight);
      align-items: center;
      background: rgb(230, 230, 230);
      color: rgb(0, 55, 155);
      font-size: 24px;
      /* font: 24px 'Roboto', sans-serif; */
      border-radius: 5px;

      p {
        padding-bottom: 15px;
        font-size: 16px;
        font-weight: 700;
      }
    }
    a.signup:hover {
      background: white;
    }

    button {
      align-items: center;
      background: none;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0;
    border: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    align-content: center;

    overflow: scroll;

    max-width: 40%;
    background: rgba(0, 55, 155, 0.9);

    div,
    div.middle,
    div.right {
      align-content: center;
      text-align: center;
      padding: 10px 0;
      overflow: hidden;
      margin-left: 0;
      display: flex;
      width: 100%;
      height: auto;
      flex-direction: column;
      text-align: center;
      img {
        width: 50px;
        height: 50px;
      }

      h1,
      h2,
      h3,
      p,
      a,
      a.hover,
      a.signup,
      a.title,
      div.profile,
      div.right {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        border: 0;
        padding: 0;
        align-items: center;
        align-content: center;
        flex-direction: column;
        font-size: 14px;

        div {
          width: 100%;
          text-align: center;
          margin-left: auto;
          margin-right: auto;

          svg {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      a.signup {
        width: 100%;
        padding: 10px;
      }
      a.policytitle {
        font-size: 14px;
      }
    }

    ${(props) =>
      props.type === 'close' &&
      css`
        transition: 0.5s;
        transform: translateX(-100%);
      `}

    ${(props) =>
      props.type === 'open' &&
      css`
        transition: 0.5s;
        transform: translateX(0%);
      `}
  }
`;

export const ButtonIcon = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 5px;
    z-index: 201;

    height: auto;

    ${(props) =>
      props.type === 'open' &&
      css`
        transition: 0.5s;
        transform: translate(40vw) rotateZ(180deg);
      `}

    ${(props) =>
      props.type === 'close' &&
      css`
        transition: 0.5s;
        transform: translate(0%) rotateZ(0deg);
      `}
    button, svg {
      width: 50px;
      font-size: 42px;
      font-weight: 700;
      color: #000;
      background: none;
      border: none;
    }
  }
`;
