import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  width: 500px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 80vw;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    form, input, button {
      max-width: 100%;
    }
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    line-height: 64px;

    button {
      margin-left: auto;
      margin-right: auto;
      width: 200px;
    }

    h1 {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      svg {
      height: 100%;
      width: auto;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      }
    }
  }

  form {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
    font: 34px 'Roboto', sans-serif;

    input {
      width: 500px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      border: 0;
      border-radius: 5px;
      margin: 5px;
      padding: 10px;
      background-color: rgba(0, 0, 150, 0.1);
      color: black;
      text-align: left;
      font: 24px 'Roboto', sans-serif;
    }

    input.alert {
      border: 1px solid red;
    }

    a {
      text-align: end;
      font: 14px 'Roboto', sans-serif;
      text-decoration: none;
      color: black;
    }

    button {
      margin-top: 5px;
      min-width: 250px;
      max-width: 90vw;
      height: 44px;
      background: rgba(0, 105, 255, 0.7);
      font-weight: bold;
      color: #fff;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, 'rgba(0, 105, 255, 1)')};
      }
      font: 34px 'Roboto', sans-serif;
    }
`;
