/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const Context = createContext();

function SideBarProvider({ children }) {
  const [sideBar, setSideBar] = useState(false);
  const toogleSidebar = () => setSideBar(!sideBar);

  return (
    <Context.Provider value={{ sideBar, toogleSidebar }}>
      {children}
    </Context.Provider>
  );
}

export { Context, SideBarProvider };

SideBarProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
