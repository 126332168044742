import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  --white: #fdfdfd;
  --datagroblue: #110044;

  color: #333;
  text-align: justify;

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 80vw;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 300px;
    border-radius: 5px;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    max-width: 700px;

    h1,
    h2,
    h3 {
      padding: 10px;
    }

    h3 {
      font-weight: 200;
    }
  }

  img {
    max-width: 500px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;
