import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  --white: #fdfdfd;
  --datagroblue: #110044;

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    div {
      max-width: 80vw;
      flex-wrap: wrap;
    }
  }

  p,
  h1 {
    color: black;
  }

  div {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;

    button {
      background: rgba(255, 255, 255, 0);
      border: none;
      padding: 0;
      margin: 0;
    }

    h2 {
      color: black;
    }
    div {
      display: flex;
      flex-direction: column;
    }
  }
`;
