import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  button {
    width: 300px;
    max-width: 90vw;
    height: 44px;
    background: rgba(0, 0, 112, 0.7);
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, 'rgba(0, 0, 112, 1)')};
    }
  }

  div.CaptchaContainer {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    border-radius: 4px;
    overflow: hidden;
  }
`;
