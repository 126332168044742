import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { Context } from '~/Context/AuthContext';

export default function RouteWrapper({ isPrivate }) {
  const { authenticated } = useContext(Context);

  if (!authenticated && isPrivate) {
    return <Navigate to="/" />;
  }

  if (authenticated && !isPrivate) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
