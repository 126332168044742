import React from 'react';

import { RiDatabase2Fill } from 'react-icons/ri';

import DashMenu from '~/Components/DashMenu';
import Footer from '~/Components/Footer';
import { Container } from './styles';

function ContactUs() {
  return (
    <>
      <DashMenu />
      <Container>
        <div>
          <RiDatabase2Fill size={50} />
          <h1>Datagro Soluções Tecnológicas</h1>
        </div>
        <div>
          <h3>Email: </h3>
          <h3 className="light"> datagro.br@gmail.com</h3>
        </div>
        <div>
          <h3>Telefone: </h3>
          <h3 className="light"> (66) 992276047</h3>
        </div>
        <br />
        <br />
        <br />
        <div>
          <h4>
            Mande suas dúvidas e/ou sugestões. Teremos o prazer em responder.
          </h4>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default ContactUs;
