/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import api, { getAuthorizationHeader } from '~/services/api';

const Context = createContext();

// import { Container } from './styles';

function AuthProvider({ children }) {
  const clientInfo = sessionStorage.getItem('clientInfo');
  const [authenticated, setAuthenticated] = useState(!!clientInfo);
  const [sessionTokenGlobal, setSessionTokenGlobal] = useState(
    sessionStorage.getItem('sessionToken')
  );

  function handleLogin(client, token, sessionToken) {
    sessionStorage.setItem('clientInfo', JSON.stringify(client));
    localStorage.setItem('token', token);
    sessionStorage.setItem('sessionToken', sessionToken);
    setSessionTokenGlobal(sessionToken);
    setAuthenticated(true);
  }

  function handleLogout() {
    setAuthenticated(false);
    sessionStorage.removeItem('clientInfo');
    localStorage.removeItem('token');
    sessionStorage.removeItem('sessionToken');
  }

  async function checkSessionToken() {
    const body = {
      userToken: sessionTokenGlobal,
    };
    const lastToken = await api.post('/clientsessioncheck', body, {
      headers: { Authorization: getAuthorizationHeader() },
    });

    if (!(sessionTokenGlobal === lastToken.data.token)) {
      handleLogout();
    }

    // console.log('checou!');
  }

  // useEffect(() => {
  //   // const backtoken = await api.post("/colectorsessioncheck", body);

  //   checkSessionToken();

  //   if (sessionToken === backToken) {
  //     setAuthenticated(true);
  //   } else {
  //     setAuthenticated(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [authenticated, sessionToken]);

  return (
    <Context.Provider
      value={{
        authenticated,
        sessionTokenGlobal,
        handleLogin,
        handleLogout,
        checkSessionToken,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
