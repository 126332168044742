import styled from 'styled-components';

export const Container = styled.div`
  align-self: center;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  label {
    cursor: pointer;
    width: 100%;
    height: auto;
    &:hover {
      opacity: 0.7;
    }
    img {
      align-content: center;
      align-items: center;
      height: 200px;
      width: 100%;
      border-radius: 50%;
      border: 3px solid rgba(255, 255, 255, 0.3);
      background: #eee;
    }
    input#avatar {
      display: none;
    }
  }
`;
