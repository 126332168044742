import styled from 'styled-components';

export const Container = styled.div`
  --datagroblue: #110044;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 100vw;
  background: var(--datagroblue);

  div {
    flex-direction: row;
    width: 100%;
    text-align: center;

    a {
      width: auto;
      float: right;
      display: flex;
      align-items: center;
      align-content: center;
      background: #ccc;
      text-decoration: none;
      color: var(--datagroblue);
      border-radius: 5px;
      border: 5px;
      padding: 5px;
      margin: 5px;

      h4 {
        padding: 5px;
      }
    }

    a:hover {
      background: white;
    }
  }
  div.logo {
    display: flex;
    align-items: center;
    align-content: center;

  }


  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    div, div.logo, svg {
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      align-content: center;
      width: 100%;
    }
    svg {
      width: 30px;
    }
    h1, h2, h3, h4 {
      font-size: 10px;
      text-align: center;
    }
    overflow: hidden;
`;
