import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import api, { getAuthorizationHeader } from '~/services/api';

import { BsFillCartXFill } from 'react-icons/bs';

import { Context } from '~/Context/CartContext';
import { Container } from './styles';

export default function ProductCart({ item, product, plan, price }) {
  const { removeFromCart, buyProduct } = useContext(Context);

  const signatures = [
    {
      name: 'ForTrees',
      acessLink:
        'https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c9380848451e652018453aaf0af0224',
    },
    {
      name: 'ForTrees Pro',
      acessLink:
        'https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c93808484a6818e0184e46c3a5530c6',
    },
  ];

  function getLinkAccess(productname) {
    const link = signatures.filter((l) => l.name === productname);

    return link[0].acessLink;
  }

  const productToRemove = {
    item,
    product,
    price,
  };

  async function buyHandler(prod) {
    const productToBuy = {
      productname: prod,
    };
    try {
      api.post('/sells', productToBuy, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      toast.success('Pedido realizado. Aguardando pagamento.');

      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 4000));
      const theLink = getLinkAccess(prod);
      window.open(theLink);
      buyProduct(productToRemove);
    } catch (err) {
      console.log(err);
      toast.error('Falha no pedido');
    }
  }

  return (
    <Container>
      <div key={item}>
        <div>
          <h1>{product}</h1>
        </div>
        <div>
          <h1>{plan}</h1>
        </div>
        <div>
          <h1>12 meses</h1>
        </div>

        <div>
          <h1>{price.toFixed(2)} R$</h1>
        </div>
        <div className="comprar">
          <button onClick={() => buyHandler(product)} type="submit">
            <h1>Comprar</h1>
          </button>
        </div>
        <div>
          <button
            className="cartDelete"
            type="submit"
            onClick={() => removeFromCart(productToRemove)}
          >
            <BsFillCartXFill size={30} />
          </button>
        </div>
      </div>
    </Container>
  );
}

ProductCart.propTypes = {
  plan: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  product: PropTypes.string.isRequired,
  item: PropTypes.number.isRequired,
};
