import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: grey 5px 2px 5px 2px;
  border-radius: 5px;

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    overflow: scroll;
  }

  div {
    display: flex;
    width: 100%;
    /* background: orange; */
    box-shadow: none;
    align-items: center;
    padding: 10px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;

    div {
      /* background: green; */
      width: 100%;

      h1 {
        width: auto;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }

      button.cartDelete {
        border-radius: 5px;
        padding: 0 10px;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        /* height: 70px; */
        vertical-align: middle;
        align-items: center;
        border: none;
        display: flex;
        background: rgba(255, 255, 255, 0.9);

        svg {
          width: 100%;
          height: auto;
          color: black;
          border: 0;
        }
      }

      button.cartDelete:hover {
        background: rgba(0, 0, 0, 0.9);
        svg {
          color: white;
        }
      }
    }

    div.comprar {
      width: auto;
      vertical-align: middle;
      align-items: center;
      /* background: red; */
      button {
        /* height: 70px; */
        padding: 20px;
        border-radius: 5px;
        background: rgba(0, 105, 255, 0.7);
        color: rgba(255, 255, 255, 1);
      }
      button:hover {
        background: rgba(0, 105, 255, 1);
      }
    }
  }
`;
