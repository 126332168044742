import React, { useContext, useState } from 'react';

import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { RiDatabase2Fill } from 'react-icons/ri';
import { BsCartCheckFill, BsUiChecksGrid } from 'react-icons/bs';
import { MdContactMail } from 'react-icons/md';

import { Context } from '~/Context/AuthContext';
import { Context as CartContext } from '~/Context/CartContext';
import { Context as SidebarContext } from '~/Context/SidebarContext';

import { NavLink } from 'react-router-dom';
import { Container, ButtonIcon } from './styles';

const activeStyle = {
  textDecoration: 'underline',
  color: 'rgba(0, 0, 0, 0.7)',
};

function DashMenu() {
  const { handleLogout } = useContext(Context);
  const { cartListCount } = useContext(CartContext);
  const { sideBar, toogleSidebar } = useContext(SidebarContext);
  const client = JSON.parse(sessionStorage.getItem('clientInfo'));

  const [bar, setBar] = useState(<AiOutlineMenuUnfold />);
  function changeToogleSign() {
    if (sideBar === true) {
      setBar(<AiOutlineMenuUnfold />);
    } else {
      setBar(<AiOutlineMenuUnfold />);
    }
    toogleSidebar();
    return bar;
  }

  return (
    <aside>
      <Container type={sideBar === true ? 'open' : 'close'}>
        <div>
          <div>
            <NavLink to="/dashboard">
              <RiDatabase2Fill size={50} />
              <h1>Datagro Soluções Tecnológicas</h1>
            </NavLink>
          </div>
          <div>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/contactus"
            >
              <MdContactMail size={30} />
              <h3>Sobre</h3>
            </NavLink>
          </div>
          <div className="right">
            <div>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/clientsystems"
              >
                <BsUiChecksGrid size={50} />
                <p>Meus Sistemas</p>
              </NavLink>
            </div>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/profile"
              className="profile"
            >
              <div>
                <div>
                  {client.avatar ? (
                    <img src={client.avatar.url} alt="avatar" />
                  ) : (
                    <img
                      src="https://i.pinimg.com/originals/3f/94/70/3f9470b34a8e3f526dbdb022f9f19cf7.jpg"
                      alt="avatar"
                    />
                  )}
                </div>
                <p>{client.name}</p>
              </div>
            </NavLink>
            <div>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="signup"
                to="/cart"
              >
                <BsCartCheckFill size={25} />
                Carrinho
                <p>{cartListCount}</p>
              </NavLink>
            </div>
          </div>
          <button type="button" onClick={handleLogout}>
            Sair
          </button>
        </div>
      </Container>
      <ButtonIcon
        type={sideBar ? 'open' : 'close'}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={changeToogleSign}
      >
        <button type="submit">{bar}</button>
      </ButtonIcon>
    </aside>
  );
}

export default DashMenu;
