import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { ToastContainer } from 'react-toastify';
import history from '~/services/history';
import GlobalStyle from '~/styles/global';
import Routes from './Routes';

import { AuthProvider } from './Context/AuthContext';
import { CartProvider } from './Context/CartContext';
import { SideBarProvider } from './Context/SidebarContext';

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <SideBarProvider>
          <Router history={history}>
            <Helmet>
              <title>Datagro</title>
              <link
                rel="icon"
                type="image/png"
                href="favico.ico"
                sizes="16x16"
              />

              <style type="text/css">
                {`
                * {
                  margin: 0;
                  padding: 0;
                  outline: 0;
                  box-sizing: border-box;
                }
                *:focus {
                  outline: 0;
                }
                html, body, #app {
                  height: 100%;
                  background: rgba(255, 255, 255, 0.8);
                }
                body {
                  -webkit-font-smoothing: antialiased;
                }

              }
              body, input, button {
                font: 14px 'Roboto', sans-serif;

              }
              a {
                text-decoration: none;
              }
              ul {
                list-style: none;
              }
              button {
                cursor: pointer;
              }
              `}
              </style>
            </Helmet>
            <Routes />
            <GlobalStyle />
            <ToastContainer autoclose={5000} />
          </Router>
        </SideBarProvider>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
