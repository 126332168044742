/* eslint-disable no-promise-executor-return */
import React from 'react';

import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Input from '~/Components/Input';

import { useNavigate } from 'react-router-dom';

import { RiDatabase2Fill } from 'react-icons/ri';

import api, { getAuthorizationHeader } from '~/services/api';
import { Container } from './styles';

const schema = Yup.object().shape({
  newpassword: Yup.string().required('Insira sua senha'),
  confirmNewpassword: Yup.string().required('Repita sua senha'),
});

function UserReset({ token, subdomain }) {
  const navigate = useNavigate();
  // const recaptchaRef = useRef();

  async function handleButton(dataForm) {
    // const recaptchaValue = recaptchaRef.current.getValue();

    // dataForm.recaptchaValue = recaptchaValue;
    // if (recaptchaValue) {
    try {
      await api.post(`/${subdomain}/${token}`, dataForm, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      toast.success('Senha Atualizada com Sucesso');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      navigate('/');
    } catch (err) {
      toast.error('Não é mais possível atualizar esta senha');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      navigate('/');
    }
    // } else {
    //   toast.error("Confime que você não é um robô.");
    // }
  }

  // function onChange() {
  //   const recaptchaValue = recaptchaRef.current.getValue();

  //   // console.log('Captcha value:', captchaToken);

  //   // console.log(recaptchaValue);

  //   if (recaptchaValue) {
  //     console.log('True');
  //   }
  // }
  return (
    <Container>
      <div>
        <h1>
          <RiDatabase2Fill size={50} color="rgb(0, 0, 0)" />
          Datagro Soluções Tecnológicas
        </h1>
      </div>
      <Form schema={schema} onSubmit={(data) => handleButton(data)}>
        <Input
          name="newpassword"
          // value={newpassword}
          // onChange={setNewpassword}
          type="password"
          placeholder="Sua nova senha"
        />
        <Input
          name="confirmNewpassword"
          // value={confirmNewpassword}
          // onChange={setConfirmNewpassword}
          type="password"
          placeholder="Repita sua nova senha"
        />

        {/* <CaptchaContainer>
          <ReCAPTCHA
            className="ReCAPTCHA"
            sitekey="6Lda8tshAAAAAErCIEBDaDcV5Ki7o31CT-RhCm3C"
            // onChange={onChange}
            ref={recaptchaRef}
            theme="dark"
          />
        </CaptchaContainer> */}
        <button type="submit">
          <p>Enviar</p>
        </button>
      </Form>
    </Container>
  );
}

UserReset.propTypes = {
  token: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
};

export default UserReset;
