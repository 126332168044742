import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  padding-top: 100px;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  --white: #fdfdfd;
  --datagroblue: #110044;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;

    .halfsize {
      width: 50%;
    }

    img {
      max-width: 700px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
    img.desktop {
      padding-top: 150px;
      width: 600px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }

    h1.main {
      padding: 20px;
      line-height: 64px;
      font-size: 48px;
      font-weight: 100;
      text-align: center;
    }
    h2.main {
      line-height: 64px;
      font-size: 32px;
      color: #777;
      font-weight: 200;
      padding: 20px;
    }
    h3.main {
      line-height: 64px;
      font-size: 28px;
      color: #777;
      font-weight: 200;
      padding: 20px;
    }

    button {
      background: rgba(255, 255, 255, 0);
      border: none;
      padding: 0;
      margin: 0;
    }

    h2 {
      color: black;
    }
    div {
      display: flex;
      flex-direction: column;
    }

    a {
      text-decoration: none;
      color: black;
    }
  }

  div {
    .progress-bar {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: var(--datagroblue);
      transform-origin: 0%;
    }
  }

  div {
    .slider {
      /* background: red; */
      /* overflow: hidden; */
    }
  }

  div.table {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    div,
    div.halfsize {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      img,
      img.desktop {
        /* margin: 0; */
        padding: 0;
        border: 0;
        max-width: 50vw;
        height: auto;
      }
      h1,
      h2,
      h3,
      h1.main,
      h2.main,
      h3.main {
        font-size: 1.5em;
        line-height: 1.5em;
        padding: 0;
        border: 0;
        text-align: center;
      }
    }
  }
`;
