import React from 'react';

import MainMenu from '~/Components/MainMenu';
import { Container } from './styles';

function DataPolicys() {
  return (
    <>
      <MainMenu />
      <Container>
        <div>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40QOHiE7vlcz4s0q1EUTJX4L1fW0Le9EPE9LGu8qP4gVxIMS50UMSCnAEhMeQZ-fcWcw&usqp=CAU"
            alt="DataPolicy"
          />
        </div>
        <div>
          {/* <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ40QOHiE7vlcz4s0q1EUTJX4L1fW0Le9EPE9LGu8qP4gVxIMS50UMSCnAEhMeQZ-fcWcw&usqp=CAU"
          alt="DataPolicy"
        /> */}
          <h1>Política de Dados</h1>
        </div>

        <div>
          <h2>Sobre os sistemas:</h2>
          <h3>
            O Sistemas Datagro servem para o planejamento e execução de
            atividades agropecuárias.
          </h3>
          <h3>
            As informações armazenadas estarão disponíveis para o usuário até um
            ano de carência após o vencimento da assinatura.
          </h3>
          <br />
          <h2>Dados pessoais e privacidade do usuário:</h2>
          <h3>
            As informações pessoais incluem as informações cadastrais como:
            nome, email, cpf e informações de endereço para emissão de nota
            fiscal ao usuário.
          </h3>
          <h3>
            O aplicativo de campo solicitará acesso a localização para navegação
            de campo; além da disponibilidade de rede de dados para baixar ou
            subir os dados.
          </h3>
        </div>
        <div>
          <h2>Sobre o uso dos dados pessoais:</h2>
          <h3>
            1. Os dados que você compartilhar com nosso sistema não serão
            compartilhados com terceiros em nenhuma hipótese.
          </h3>
        </div>
        <div>
          <h3>
            2. Os dados utilizados pelo sistema servem unicamente para atender o
            funcionamento do sistema.
          </h3>
        </div>
      </Container>
    </>
  );
}

export default DataPolicys;
