/* eslint-disable no-promise-executor-return */
/* eslint-disable react/jsx-no-bind */
// import React, { useContext } from 'react';
import React, { useRef } from 'react';

import { RiDatabase2Fill } from 'react-icons/ri';

// import { Context } from '~/Context/AuthContext';

import MainMenu from '~/Components/MainMenu';

import { toast } from 'react-toastify';

import { useNavigate, Link } from 'react-router-dom';

import api, { getAuthorizationHeader } from '~/services/api';

import { Form } from '@unform/web';
import Input from '~/Components/Input';

import { Container } from './styles';

export default function ForgotPass() {
  const formRef = useRef(null);
  // const { authenticated, handleLogin } = useContext(Context);
  // console.log(authenticated);

  const navigate = useNavigate();

  function functionThatResetsForm() {
    // You can also clear a single input value
    formRef.current.reset();
  }

  async function handleSubmit(data) {
    const { email } = data;
    console.log(email);

    try {
      await api.put(`/clientreset`, data, {
        headers: { Authorization: getAuthorizationHeader() },
      });

      toast.success('Acesse seu email para recuperar sua senha de acesso');
      functionThatResetsForm();
      await new Promise((resolve) => setTimeout(resolve, 5000));

      navigate('/');
    } catch (err) {
      toast.error(`${JSON.stringify(err)}`);
      toast.error(`${JSON.stringify(err.response.data.error)}`);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      navigate('/');
    }

    // handleLogin();
  }

  return (
    <>
      <MainMenu />
      <Container>
        <div>
          <h1>
            <RiDatabase2Fill size={50} color="rgb(0, 0, 0)" />
            Datagro Soluções Tecnológicas
          </h1>
        </div>
        <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
          <Input name="email" type="email" placeholder="Email" />
          <Link to="/signin">Entrar</Link>
          <button type="submit">Recuperar Senha</button>
        </Form>
      </Container>
    </>
  );
}
