import styled from 'styled-components';
// import { darken } from 'polished';

export const Container = styled.div`
  height: 100%;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  --white: #fdfdfd;
  --datagroblue: #110044;

  color: #333;
  text-align: justify;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding-top: 50px;
    text-align: center;

    div {
      max-width: 80vw;
    }
  }

  h3.light {
    font-weight: 200;
  }

  img {
    width: 300px;
    border-radius: 5px;
  }

  div {
    padding: 5px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    max-width: 700px;
    align-items: center;

    svg {
      height: 100%;
    }
  }

  img {
    max-width: 500px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;
