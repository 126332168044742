import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import api, { getAuthorizationHeader } from '~/services/api';

import Resizer from 'react-image-file-resizer';

import { toast } from 'react-toastify';

import { Container } from './styles';

export default function AvatarInput() {
  const { defaultValue, registerField } = useField('avatar');
  const client = JSON.parse(sessionStorage.getItem('clientInfo'));

  const [files, setFiles] = useState(defaultValue && defaultValue.id);
  const [preview, setPreview] = useState(defaultValue && defaultValue.url);

  const ref = useRef();

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
        },
        'file'
      );
    });

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'avatar_id',
        user: client.email,
        ref: ref.current,
        path: 'dataset.file',
      });
    }
  }, [client.email, ref, registerField]);

  async function handleChange(e) {
    const data = new FormData();

    const fileResizer = e.target.files[0];

    // Dá pra fazer resize aqui no front
    const image = await resizeFile(fileResizer);

    data.append('file', image);

    data.append('user', client.uuid);

    const response = await api.post('files', data, {
      headers: { Authorization: getAuthorizationHeader() },
    });

    const { id, url } = response.data;

    const clientInfo = client;

    clientInfo.avatar = {
      id,
      url,
    };

    sessionStorage.setItem('clientInfo', JSON.stringify(clientInfo));

    setFiles(id);
    setPreview(url);

    toast.success('Foto de perfil atualizada');
  }

  return (
    <Container>
      <label htmlFor="avatar">
        <img
          src={
            preview ||
            'https://i.pinimg.com/originals/3f/94/70/3f9470b34a8e3f526dbdb022f9f19cf7.jpg'
          }
          alt=""
        />

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-file={files}
          onChange={handleChange}
          ref={ref}
        />
      </label>
    </Container>
  );
}
