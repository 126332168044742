/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';
import { RiDatabase2Fill } from 'react-icons/ri';

import { Context } from '~/Context/AuthContext';

import MainMenu from '~/Components/MainMenu';

import { Link } from 'react-router-dom';

import { Form } from '@unform/web';
import Input from '~/Components/Input';

import api from '~/services/api';

// import { toast } from 'react-toastify';
import { toast } from 'react-toastify';
import { Container } from './styles';

export default function SignIn() {
  const { handleLogin } = useContext(Context);

  // const { authenticated, handleLogin } = useContext(Context);
  // console.log(authenticated);

  async function handleSubmit(data, { reset }) {
    try {
      const response = await api.post('sessions', data);
      const { client, token, sessionToken } = response.data;

      handleLogin(client, token, sessionToken);

      reset();
    } catch (err) {
      toast.error(`${JSON.stringify(err.response.data.error)}`);
    }

    // if (response.status === 400) {
    //   toast.error('Fudeu');
    // }
    // console.log(response);
  }

  return (
    <>
      <MainMenu />
      <Container>
        <div>
          <h1>
            <RiDatabase2Fill size={50} color="rgb(0, 0, 0)" />
            Datagro Soluções Tecnológicas
          </h1>
        </div>
        <Form onSubmit={handleSubmit}>
          <Input name="email" type="email" placeholder="Email" />
          <Input name="password" placeholder="Senha" type="password" />
          <Link to="/signup">Criar uma conta</Link>
          <Link to="/forgotpass">Esqueci minha senha</Link>
          <button type="submit">Entrar</button>
        </Form>
      </Container>
    </>
  );
}
